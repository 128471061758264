{
  "name": "v2c-new-cloud",
  "version": "3.1.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration staging",
    "test": "ng test",
    "serve:ssr:v2cnewcloud": "node dist/v2c-new-cloud/server/server.mjs",
    "app_start_prod": "npm run build -- --c production",
    "app_start_stg": "npm run build -- --c staging"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/fire": "^17.1.0",
    "@angular/forms": "^17.3.0",
    "@angular/material": "^17.3.10",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/platform-server": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@angular/ssr": "^17.3.8",
    "@ionic/angular": "^7.8.6",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ng-select/ng-select": "^12.0.7",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.8",
    "animate.css": "^4.1.1",
    "bootstrap": "^5.3.2",
    "chart.js": "^4.4.3",
    "express": "^4.18.2",
    "file-saver": "^2.0.5",
    "firebase": "^10.13.0",
    "jquery": "^3.7.1",
    "ng2-nouislider": "^2.0.0",
    "ngx-spinner": "^17.0.0",
    "ngx-toastr": "^19.0.0",
    "ngx-ui-switch": "^15.0.0",
    "nouislider": "^15.8.1",
    "p5": "^1.10.0",
    "pdf-lib": "^1.17.1",
    "qrcode": "^1.5.4",
    "rxjs": "~7.8.0",
    "sweetalert2": "^11.13.1",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.0",
    "@angular/localize": "^17.3.0",
    "@types/express": "^4.17.17",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.0",
    "@types/jquery": "^3.5.30",
    "@types/node": "^18.18.0",
    "@types/qrcode": "^1.5.5",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
